@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;900&display=swap');
@import url('http://fonts.cdnfonts.com/css/azo-sans');

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}

html {
  overflow-x:  hidden;
}

body {
  overflow-x: hidden;
  scroll-behavior: smooth;
  width: 100vw;
}



.quote span{
  font-family: "Azo Sans", sans-serif;
}

.other-div {
  scrollbar-width: auto;
  scrollbar-color: #fff rgba(0,0,0,0.9);
}

.other-div::-webkit-scrollbar {
  width: 16px;
}

.other-div::-webkit-scrollbar-track {
  background: #0C100E;
}

.other-div::-webkit-scrollbar-thumb {
  background-color: #fff;
  border-radius: 10px;
  border: 5px solid rgba(0,0,0,0.9);;
}

/* @media (min-width: 1100px) {
    .bm-menu {
      display: flex;
      width: 400px;
      margin-left: calc(100% - 400px + 15px);
      flex-direction: column;
      height: 100vh;
      border-radius: 0px;
      opacity: 0.8;
      z-index: 5;
      background-color: #1c1c1c;
      position: fixed;
      top: 0;
      left: 0;
    }
}

.bm-burger-button {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
}

.bm-burger-bars {
  width: 42px;
  background: #373a47;
}

.bm-menu {
  overflow: hidden;
}
@media (max-width: 1100px) {
  .bm-menu{
    display: flex;
    width: 250px;
    margin-left: calc(100% - 289px + 15px);
    flex-direction: column;
    height: 330px;
    border-radius: 15px;
    opacity: 0.95;
    z-index: 5;
    background-color: white;
    position: fixed;
    top: 0;
    left: 0;
  }
}
@media (min-width: 768px) and (max-width: 1100px) {
  .bm-menu {
    width: 560px;
    margin-left: calc(100% - 559px + 15px);
  }
} */